// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n * Copyright (C) 2018 Airbus CyberSecurity (SAS)\n *\n * This program is free software: you can redistribute it and/or modify\n * it under the terms of the Server Side Public License, version 1,\n * as published by MongoDB, Inc.\n *\n * This program is distributed in the hope that it will be useful,\n * but WITHOUT ANY WARRANTY; without even the implied warranty of\n * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the\n * Server Side Public License for more details.\n *\n * You should have received a copy of the Server Side Public License\n * along with this program. If not, see\n * <http://www.mongodb.com/licensing/server-side-public-license>.\n */\n._3lgSrD4n5i4IlRHrS5INSq {\n    font-family: monospace;\n    font-size: 12px;\n    overflow-wrap: break-word;\n    white-space: pre-wrap;\n}", "",{"version":3,"sources":["webpack://./src/web/components/event-notifications/LoggingAlertSummary.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;EAeE;AACF;IACI,sBAAsB;IACtB,eAAe;IACf,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":["/**\n * Copyright (C) 2018 Airbus CyberSecurity (SAS)\n *\n * This program is free software: you can redistribute it and/or modify\n * it under the terms of the Server Side Public License, version 1,\n * as published by MongoDB, Inc.\n *\n * This program is distributed in the hope that it will be useful,\n * but WITHOUT ANY WARRANTY; without even the implied warranty of\n * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the\n * Server Side Public License for more details.\n *\n * You should have received a copy of the Server Side Public License\n * along with this program. If not, see\n * <http://www.mongodb.com/licensing/server-side-public-license>.\n */\n:local(.bodyPreview) {\n    font-family: monospace;\n    font-size: 12px;\n    overflow-wrap: break-word;\n    white-space: pre-wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyPreview": "_3lgSrD4n5i4IlRHrS5INSq"
};
export default ___CSS_LOADER_EXPORT___;
